
import { Options, Vue } from "vue-class-component";
import Hero from "@/components/layout/Hero.vue";
// import Portfolio from "@/components/layout/Portfolio.vue";
// import EducationAndCertifications from "@/components/layout/EducationAndCertifications.vue";
// import Work from "@/components/layout/Work.vue";
// import Blog from "@/components/layout/Blog.vue";
// import Quote from "@/components/layout/Quote.vue";
// import Footer from "@/components/layout/Footer.vue";

@Options({
  name: "HomePage",
  components: {
    Hero,
    // Portfolio,
    // EducationAndCertifications,
    // Work,
    // Blog,
    // Quote,
    // Footer,
  },
})
export default class App extends Vue {}
