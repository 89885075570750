
import Envelope from "@/components/svgs/Envelope.vue";
import Github from "@/components/svgs/Github.vue";
import Linkedin from "@/components/svgs/Linkedin.vue";
import Twitter from "@/components/svgs/Twitter.vue";
import Php from "@/components/svgs/Php.vue";
import Js from "@/components/svgs/Js.vue";
import Laravel from "@/components/svgs/Laravel.vue";
import Vuejs from "@/components/svgs/Vuejs.vue";
import React from "@/components/svgs/React.vue";
import Node from "@/components/svgs/Node.vue";
import Ts from "@/components/svgs/Ts.vue";
import Docker from "@/components/svgs/Docker.vue";
import Linux from "@/components/svgs/Linux.vue";
import Git from "@/components/svgs/Git.vue";
import Aws from "@/components/svgs/Aws.vue";
import Mongodb from "@/components/svgs/Mongodb.vue";
import Mysql from "@/components/svgs/Mysql.vue";
import Python from "@/components/svgs/Python.vue";
import Serverless from "@/components/svgs/Serverless.vue";

import { Options, Vue } from "vue-class-component";
@Options({
  name: "Hero",
  components: {
    Envelope,
    Github,
    Linkedin,
    Twitter,
    Php,
    Js,
    Laravel,
    Vuejs,
    React,
    Node,
    Ts,
    Docker,
    Linux,
    Git,
    Aws,
    Mongodb,
    Mysql,
    Python,
    Serverless
  },
})
export default class Hero extends Vue {}
